import gql from "graphql-tag";

const GET_POWER_BI_REPORTS = gql`
  query GetPowerBiReports($filters: PowerBIFilterInput!) {
    reports {
      powerBI(filters: $filters) {
        result {
          embedToken
          embedUrl
          reportId
        }
        metadata {
          conversationId
          message
          statusCode
          statusText
        }
      }
    }
  }
`;

export { GET_POWER_BI_REPORTS };
